import swiper from "swiper";

// about
var aboutSlider = new swiper(".top-about-image__inner", {
  loop: true,
  effect: 'fade',
  speed: 2000,
  autoplay: true,
  spaceBetween: 0,
  centeredSlides: true,
  observer: true,
  allowTouchMove: true,
  preloadImages: false,
  autoHeight: true,
  slidesPerView: 1,
  lazy: {
    loadPrevNext: true,
    loadPrevNextAmount: 2,
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
  navigation: {
    nextEl: ".top-about-image-next",
    prevEl: ".top-about-image-prev",
  },
});


// profile
var profileSlider01 = new swiper(".top-profile-image__top-inner", {
  loop: true,
  effect: 'fade',
  speed: 2000,
  autoplay: true,
  spaceBetween: 0,
  centeredSlides: true,
  observer: true,
  allowTouchMove: true,
  preloadImages: false,
  autoHeight: true,
  slidesPerView: 1,
  lazy: {
    loadPrevNext: true,
    loadPrevNextAmount: 2,
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
  navigation: {
    nextEl: ".top-profile-image__top-next",
    prevEl: ".top-profile-image__top-prev",
  },
});

var profileSlider02 = new swiper(".top-profile-image__bottom-inner", {
  loop: true,
  effect: 'fade',
  speed: 2000,
  autoplay: true,
  spaceBetween: 0,
  centeredSlides: true,
  observer: true,
  allowTouchMove: true,
  preloadImages: false,
  autoHeight: true,
  slidesPerView: 1,
  lazy: {
    loadPrevNext: true,
    loadPrevNextAmount: 2,
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
  navigation: {
    nextEl: ".top-profile-image__bottom-next",
    prevEl: ".top-profile-image__bottom-prev",
  },
});

