// import {TweenMax} from "gsap/TweenMax";
import ScrollMagic from "scrollmagic";
// import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators.js';


var controller = new ScrollMagic.Controller();

var scene_hero = new ScrollMagic.Scene({
  triggerElement: "body",
  triggerHook: 'onLeave',
  duration: 500,
  offset: 0
})
  // .addIndicators()
  .addTo(controller);

scene_hero.on('enter', () => {
  //triggerElementを過ぎたとき
  $('.top-ticketLink').addClass('-scroll');
});
scene_hero.on('leave', () => {
  //triggerElementを抜けたとき
  $('.top-ticketLink').removeClass('-scroll');
});
